import React from 'react';
import { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

import { StyledCalendar } from './styles';

import type { CalendarProps } from './types';

export default function Calendar(props: CalendarProps) {
  const months = new DateObject().months.map((month) => month.name);
  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  return (
    <StyledCalendar
      format="ddd, MMM DD, YYYY"
      months={months}
      plugins={[<DatePanel key="date-panel-0" />]}
      showOtherDays
      weekDays={weekDays}
      {...props}
    />
  );
}

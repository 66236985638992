import React from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';

const TextNumberCustom = React.forwardRef(function TextNumberCustom(
  { allowNegative, onChange, ...props }: NumericFormatProps,
  ref: React.Ref<typeof NumericFormat>
) {
  return (
    <NumericFormat
      allowNegative={allowNegative}
      decimalScale={2}
      decimalSeparator="."
      displayType="input"
      getInputRef={ref}
      onValueChange={(values) => {
        onChange?.({
          //@ts-ignore
          target: {
            name: props.name as string,
            value: values.floatValue as any,
          },
        });
      }}
      thousandSeparator={true}
      thousandsGroupStyle="thousand"
      type="text"
      {...props}
    />
  );
});
export default TextNumberCustom;

import React, { useMemo } from 'react';

import FilterMenuAutocomplete from '../FilterMenuAutocomplete';

import type { FilterMenuOption } from '@goodfynd/react-web.ui.filter-menu';
import type { FilterMenuFacetProps } from './types';
export default function FilterMenuFacet({
  options,
  setValues,
  ...props
}: FilterMenuFacetProps) {
  const selectedIds = useMemo(
    () => props.value.map((option) => option.id || option.value),
    [props.value]
  );

  if (!options[0] && !props.busy && !props.value[0]) {
    return null;
  }

  return (
    <FilterMenuAutocomplete
      {...props}
      onChange={(option) => {
        let facets: FilterMenuOption[] = [...props.value];
        if (facets.find((facet) => facet.value === option.value)) {
          facets = facets.filter((facet) => facet.value !== option.value);
        } else {
          facets.push(option);
        }

        setValues(facets);
      }}
      options={options}
      value={options.filter((option) => selectedIds.includes(option.value))}
    />
  );
}

import { useRouter } from 'next/router';

import { useAuth0 } from '@auth0/auth0-react';
import { gtmUtil } from '@goodfynd/react-web.utils.gtm-util';
import { createPath } from '@utils/string-util';

import routes from '../../config/routes';
import { userAuthenticated } from './utils';

import type { AuthState } from '@auth0/auth0-react/dist/auth-state';
export interface AuthContextInterface extends AuthState {
  getAccessToken: () => Promise<string>;
  getLoginUrl: () => string;
  handleCallback: () => void;
  loginWithRedirect: () => void;
  logout: (returnTo?: string) => Promise<void>;
  signUp: () => Promise<void>;
}

export const useAuth = (): AuthContextInterface => {
  const {
    error,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout: unauthorize,
    user,
  } = useAuth0();

  const router = useRouter();

  const logout = async (returnTo?: string) => {
    await unauthorize({
      logoutParams: {
        returnTo: returnTo || window.location.origin + routes.account.logout,
        federated: false,
      },
    });

    gtmUtil.pushEvent({
      event: 'fynd_logout',
    });
  };

  return {
    error,
    getAccessToken: async () => {
      return getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.NEXT_PUBLIC_AUDIENCE,
          scope: 'openid email offline_access',
        },
      });
    },
    getLoginUrl: () => {
      return window.location.href.includes(routes.account.signIn)
        ? routes.account.signIn
        : routes.account.signIn + `?returnUrl=${window.location.href}`;
    },
    handleCallback: () => {
      const { hasError, redirectUrl } = userAuthenticated(isLoading, error);
      if (!hasError || !redirectUrl) {
        return;
      }

      if (error?.message === 'unverified') {
        logout(redirectUrl);
        return;
      }

      redirectUrl.startsWith('/')
        ? router.replace(redirectUrl)
        : (window.location.href = redirectUrl as string);
    },
    isAuthenticated,
    isLoading,
    loginWithRedirect: () => {
      let returnTo =
        (router.query.returnUrl as string) ||
        createPath(window.location.origin, routes.dashboard.home);
      if (returnTo === routes.account.logout) {
        returnTo = routes.home;
      }
      const options = {
        appState: {
          returnTo,
        },
      };
      loginWithRedirect(options);
    },
    logout,
    signUp: async () => {
      const options = {
        appState: {
          returnTo: routes.home,
        },
        redirectUri: window.location.origin + routes.account.callback,
        authorizationParams: {
          screen_hint: 'signup',
        },
      };
      loginWithRedirect(options);
    },
    user,
  };
};

import { styled } from '@goodfynd/react-web.theme';

import Image from '../../Image';

export const StyledActionContainer = styled('div', {
  display: 'flex',
  marginTop: 24,
});

export const StyledContainer = styled('section', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  // padding: '0 16px',
  paddingBottom: 32,
});

export const StyledImage = styled(Image, {
  marginBottom: 16,
});

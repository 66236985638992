const endpoints = {
  accounts: {
    autocomplete: 'users/v2/autocomplete',
    create: 'vendor-dashboard/accounts/v2',
    permissions: 'users/v2/:id/permissions',
    profile: 'vendor-dashboard/accounts/v2/:id',
    updateOrganization: 'users/v2/:id/parent',
    update: 'vendor-dashboard/accounts/v2',
    userInfo: 'vendor-dashboard/accounts/v2/user-info',
  },
  customers: {
    base: 'customers/:id',
    autocompleteVendors: 'vendor-dashboard/customers/v2/autocomplete',
  },
  catalog: {
    productImport:
      'vendor-dashboard/catalog-import/v2/:vendorId/product/:organizationProductId',
    productGroupImport:
      'vendor-dashboard/catalog-import/v2/:vendorId/product-group/:organizationProductGroupId',
    categoryImport:
      'vendor-dashboard/catalog-import/v2/:vendorId/category/:organizationCategoryId',
    productOptionImport:
      'vendor-dashboard/catalog-import/v2/:vendorId/product-option/:organizationProductOptionId',
    productSync:
      'organization-dashboard/products/v2/:id/refresh-vendor-products',
  },
  categories: {
    base: 'vendor-dashboard/categories/v2/:id',
    delete: 'vendor-dashboard/categories/v2/:id',
    search: 'vendor-dashboard/categories/v2',
    toggle: 'vendor-dashboard/categories/v2/:id/visible/:visible',
    organization: {
      base: 'organization-dashboard/categories/v2/:id',
      search: 'organization-dashboard/categories/v2',
      toggle: 'organization-dashboard/categories/v2/:id/visible/:visible',
      vendor: 'organization-dashboard/categories/v2/vendor/:vendorId',
      export: 'organization-dashboard/catalog-export/v2/category/:id',
    },
  },
  cms: {
    link: '/api/cms/link',
    retrieve: '/api/cms/retrieve',
    search: '/api/cms/search',
  },
  dietary: {
    base: 'vendor-dashboard/dietary-tags/v2/:id',
    search: 'vendor-dashboard/dietary-tags/v2',
  },
  events: {
    autocomplete: 'vendor-dashboard/lots/v2/autocomplete',
    byLot: 'vendor-dashboard/events/v2/lot/:lotId',
    like: 'events/like/:id',
    lotSearch: 'vendor-dashboard/events/v2/search',
    search: 'vendor-dashboard/vendor-events/v2/search',
  },
  invoices: {
    create: 'invoices/v2/create',
    credit: 'invoices/v2/credit/:id',
    edit: 'invoices/v2/edit/:id',
    finalize: 'invoices/v2/:id/finalize/:tip',
    quote: {
      accept: 'invoices/v2/quote/:id/accept',
      create: 'invoices/v2/quote',
      edit: 'invoices/v2/quote/:id/edit',
      cancel: 'invoices/v2/quote/:id/cancel',
    },
    refund: 'invoices/v2/refund/:id',
    voidInvoice: 'invoices/v2/void/:id',
  },
  license: {
    delete: 'vendor-dashboard/vendors/v2/:vendorId/licenses/:id',
    list: 'admin-dashboard/licenses/v2',
    update: 'vendor-dashboard/vendors/v2/:vendorId/licenses',
  },
  locations: {
    byIP: 'https://ipapi.co/json',
    getCounty: 'https://geo.fcc.gov/api/census/area',
  },
  lots: {
    autocomplete: 'vendor-dashboard/lots/v2/autocomplete',
    base: 'vendor-dashboard/lots/v2/:id',
    events: {
      awaitingConfirmation:
        'event-dashboard/lots/v2/events/awaiting-confirmation/:id',
      confirmed: 'event-dashboard/lots/v2/events/confirmed/:id',
      interested: 'event-dashboard/lots/v2/events/interested/:id',
    },
    interest: {
      add: 'vendor-dashboard/lots/v2/:id/interest/add',
      remove: 'vendor-dashboard/lots/v2/:id/interest/remove',
    },
    search: 'lots/v2',
    updateOrganization: 'event-dashboard/lots/v2/:id/parent',
    vendorConfirm: 'vendor-dashboard/lots/v2/:id/confirm',
    vendorRejection: 'vendor-dashboard/lots/v2/:id/reject',
    bookVendor: 'vendor-dashboard/lots/v2/:id/select',
  },
  orders: {
    base: 'orders/v2/:id',
    cancel: 'orders/cancel/:id',
    clear: 'orders/clear/:id',
    complete: 'orders/complete/:id/:transactionId',
    count: 'vendor-dashboard/order-search/v2/vendor/count',
    findCustomer: 'orders/customer',
    merchants: 'orders/merchants/:id',
    pickup: 'orders/pickup',
    prepare: 'orders/prepare/:id',
    promo: 'orders/promo/:code',
    ready: 'orders/ready/:id',
    receipt: {
      image: 'orders/receipt/image/:transactionId/:chefView',
    },
    refund: 'orders/refund/:id',
    remove: 'orders/remove/:id',
    reports: 'orders/export',
    search: 'vendor-dashboard/order-search/v2/vendor',
    send: {
      receipt: 'orders/send/receipt/:id',
      sms: 'orders/send/sms/:id',
    },
    track: {
      print: 'orders/track/print/:id',
    },
    transactions: 'orders/transactions',
    organization: {
      count: 'vendor-dashboard/order-search/v2/vendor/count',
    },
  },
  organizations: {
    accountStatus: 'v3/vendor-dashboard/organizations/:id/account-status',
    autocomplete: 'organization-dashboard/organizations/v2/autocomplete',
    autocompleteLotsAndOrganizations:
      'organization-structure/v2/lot-organization-autocomplete',
    autocompleteVendorsAndOrganizations:
      'organization-structure/v2/vendor-organization-autocomplete',
    agreements: 'v3/vendor-dashboard/organizations/:id/agreements',
    base: 'organization-dashboard/organizations/v2/:id',
    businessInfo: 'v3/vendor-dashboard/organizations/:id/business-info',
    createOnboardingLink:
      'v3/vendor-dashboard/organizations/:id/onboarding-link',
    createStripeAccount:
      'v3/vendor-dashboard/organizations/:id/create-stripe-account',
    orderingSettings: 'v3/vendor-dashboard/organizations/:id/ordering-settings',
    payoutIntervals: 'v3/vendor-dashboard/organizations/payout-intervals',
    personnel: 'users/v2',
    structure: 'organization-structure/v2/:id',
    structurev2: 'organization-dashboard/organizations/v2/:id/structure',
    updateOrganization: 'organization-dashboard/organizations/v2/:id/parent',
    contactDetails: 'organization-dashboard/contact-details/v2/:id',
    taxSettings: 'v3/vendor-dashboard/organizations/:id/tax-settings',
  },
  payouts: {
    instant: 'vendor-dashboard/payouts/v2/instant/:vendorId',
    report: 'payouts/:id',
    schedule: 'payouts/:vendorId/schedule/:intervalId',
    search: 'payouts',
    stripe: {
      connect: 'orders/stripe/connect/:id',
    },
  },
  pos: {
    capture: 'pos/capture/:vendorId',
    pay: 'pos/pay/:id',
    sales: 'pos/sales',
    token: 'pos/token/:id',
  },
  products: {
    base: 'vendor-dashboard/products/v2/:id',
    delete: 'vendor-dashboard/products/v2/:id',
    like: 'products/like/:id',
    search: 'vendor-dashboard/products/v2',
    toggle: 'vendor-dashboard/products/v2/:id/visible/:visible',
    autocomplete: 'vendor-dashboard/products/v2/autocomplete',
    menuSearch: 'vendor-dashboard/menu-search/v2',
    price: 'vendor-dashboard/products/v2/:id/price',
    organization: {
      base: 'organization-dashboard/products/v2/:id',
      search: 'organization-dashboard/products/v2',
      toggle: 'organization-dashboard/products/v2/:id/visible/:visible',
      vendor: 'organization-dashboard/products/v2/vendor/:vendorId',
      menuSearch: 'organization-dashboard/menu-search/v2',
    },
  },
  productOptions: {
    search: 'vendor-dashboard/product-options/v2',
    base: 'vendor-dashboard/product-options/v2/:id',
    organization: {
      base: 'organization-dashboard/product-options/v2/:id',
      search: 'organization-dashboard/product-options/v2',
      vendor: 'organization-dashboard/product-options/v2/vendor/:vendorId',
    },
  },
  productGroups: {
    search: 'vendor-dashboard/product-groups/v2',
    setDefault: 'vendor-dashboard/vendors/v2/:vendorId/default-menu/:id',
    base: 'vendor-dashboard/product-groups/v2/:id',
    toggle: 'vendor-dashboard/product-groups/v2/:id/visible/:visible',
    organization: {
      base: 'organization-dashboard/product-groups/v2/:id',
      search: 'organization-dashboard/product-groups/v2',
      toggle: 'organization-dashboard/product-groups/v2/:id/visible/:visible',
      vendor: 'organization-dashboard/product-groups/v2/vendor/:vendorId',
      export: 'organization-dashboard/catalog-export/v2/product-group/:id',
    },
  },
  profiles: {
    organization: 'vendor-dashboard/profiles/v2/organizations/:id',
    vendor: 'vendor-dashboard/profiles/v2/vendors/:id',
  },
  reports: {
    customerOverview: 'vendor-dashboard/order-reports/v2/customer-overview',
    items: 'vendor-dashboard/order-reports/v2/items',
    popularProducts: 'vendor-dashboard/order-reports/v2/popular-products',
    summary: 'vendor-dashboard/order-reports/v2/summary',
    trend: 'vendor-dashboard/order-reports/v2/trend',
  },
  schedule: {
    base: 'vendor-dashboard/schedules/v2/:id',
    count: 'vendor-dashboard/schedules/v2/count',
    add: 'vendor-dashboard/schedules/v2',
    lotSearch: 'event-dashboard/schedules/v2/search',
    search: 'vendor-dashboard/organization-schedules/v2/search',
    transfer: 'vendor-dashboard/schedules/v2/:scheduleId/transfer',
    walkups: 'vendor-dashboard/schedules/v2/:id/walkups',
    cancel: 'vendor-dashboard/schedules/v2/:id/cancel',
    enableOrdering: 'vendor-dashboard/schedules/v2/:id/enable-ordering',
    disableOrdering: 'vendor-dashboard/schedules/v2/:id/disable-ordering',
  },
  stripe: {
    card: 'customers/card',
  },
  territories: {
    get: 'vendor-dashboard/territories/v2',
    create: 'vendor-dashboard/territories/v2',
    update: 'vendor-dashboard/territories/v2/:id',
    delete: 'vendor-dashboard/territories/v2/:id',
  },
  user: {
    defaultVendor: 'users/v2/:userId/default-vendor/:vendorNameId',
    add: 'admin-dashboard/users/v2',
    base: 'users/v2/:id',
    availableRoles: 'users/v2/available-roles',
    search: 'users/v2',
    franchise: 'users/v2/franchise-users',
  },
  vendors: {
    add: 'vendor-dashboard/vendors/v2',
    agreements: 'vendor-dashboard/vendors/v2/:id/agreements',
    autocomplete: 'vendor-dashboard/vendors/v2/autocomplete',
    base: 'vendor-dashboard/vendors/v2/:id',
    businessInfo: 'vendor-dashboard/vendors/v2/:id/business-info',
    frequentLocations: 'vendor-dashboard/vendors/v2/:id/frequent-locations',
    licenses: 'vendor-dashboard/vendors/v2/:id/licenses',
    public: 'public/vendors/v2/:id',
    customer: 'vendor-dashboard/customers/v2/:id/vendor/:vendorId',
    map: 'vendor-dashboard/vendors/v2/:id/map',
    orderingSettings: 'v3/vendor-dashboard/vendors/:id/ordering-settings',
    orderingSchedules: 'consumer-search/vendor',
    organizationPath: 'vendor-dashboard/vendors/v2/:id/organization-path',
    products: 'vendor-dashboard/products/v2',
    sendMarketing: 'vendor-dashboard/vendors/v2/send/marketing/:id',
    settings: 'vendor-dashboard/vendors/v2/settings',
    stripeDashboard: 'vendor-dashboard/vendors/v2/dashboard/stripe/:id',
    updateOrganization: 'vendor-dashboard/vendors/v2/:id/parent',
    events: {
      awaitingConfirmation:
        'vendor-dashboard/vendor-events/v2/awaiting-confirmation/:id',
      confirmed: 'vendor-dashboard/vendor-events/v2/confirmed/:id',
      interested: 'vendor-dashboard/vendor-events/v2/interested/:id',
    },
    toggle: 'vendor-dashboard/vendors/v2/:id/visible/:visible',
    getVendorsInOrganization:
      'vendor-dashboard/vendors/v2/organization/:id/vendors',
    summary: 'vendor-dashboard/vendors/v2/:id/summary',
    taxes: 'vendor-dashboard/vendors/v2/:id/taxes',
  },
};

export default endpoints;

import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import type { OrganizationRequest } from '@components/organizations/OrganizationWizard/FormOrganization/types';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

import type { AutocompleteRequest } from '../../types/search';
import type {
  Agreement,
  BillingInfoRequest,
  OrganizationContactDetails,
  OrganizationItem,
  OrganizationMemberType,
  Suggestion,
} from '../../types/shared';
import type { User } from '../../stores/models/user';
import type { OrganizationStructure } from '@goodfynd/react-web.dashboard.organization-tree';
import type {
  AutoCompleteLotsResponse,
  AutoCompleteResponse,
  AutoCompleteVendorsResponse,
  CreateOnboardingLinkResponse,
  CreateStripeAccountResponse,
  OrderingSettingsRequest,
  OrganizationResponse,
  OrganizationStripeAccountStatusResponse,
  OrganizationStructureV2,
} from '../../services/api/types';
import type {
  OrganizationBusinessInfo,
  PayoutIntervalOption,
  TaxSettings,
} from '../../types/organizations';

export const useOrganizationApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async addOrganization(
        request: OrganizationRequest
      ): Promise<OrganizationResponse> {
        try {
          const data: OrganizationResponse = await api.postFile(
            stringUtil.replace(endpoints.organizations.base, { ':id': '' }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteResponse> {
        try {
          const data: Suggestion[] = await api.get(
            endpoints.organizations.autocomplete,
            request
          );
          return { success: true, results: data };
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async autocompleteLots(
        request: AutocompleteRequest
      ): Promise<AutoCompleteLotsResponse> {
        try {
          const data: AutoCompleteLotsResponse = await api.get(
            endpoints.organizations.autocompleteLotsAndOrganizations,
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async autocompleteVendors(
        request: AutocompleteRequest
      ): Promise<AutoCompleteVendorsResponse> {
        try {
          const data: AutoCompleteVendorsResponse = await api.get(
            endpoints.organizations.autocompleteVendorsAndOrganizations,
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async createOnboardingLink(id: string) {
        try {
          const data: CreateOnboardingLinkResponse = await api.post(
            stringUtil.replace(endpoints.organizations.createOnboardingLink, {
              ':id': id,
            }),
            {
              redirectUrl: 'test',
            }
          );
          return data;
        } catch (error: unknown) {
          if (isInputError(error) && error.status === 400) {
            return undefined;
          }
          return Promise.reject({ error });
        }
      },

      async createStripeAccount(
        id: string
      ): Promise<CreateStripeAccountResponse> {
        try {
          return await api.post(
            stringUtil.replace(endpoints.organizations.createStripeAccount, {
              ':id': id,
            }),
            {}
          );
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async get(id: string): Promise<OrganizationItem> {
        try {
          const data: OrganizationItem = await api.get(
            stringUtil.replace(endpoints.organizations.base, { ':id': id })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async getAgreements(id: string): Promise<Agreement[]> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.organizations.agreements, {
              ':id': id,
            })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getBusinessInfo(id: string): Promise<OrganizationBusinessInfo> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.organizations.businessInfo, {
              ':id': id,
            })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getContactDetails(id: string): Promise<OrganizationContactDetails> {
        try {
          const data: OrganizationContactDetails = await api.get(
            stringUtil.replace(endpoints.organizations.contactDetails, {
              ':id': id,
            })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async getPayoutIntervals(): Promise<PayoutIntervalOption[]> {
        try {
          return await api.get(endpoints.organizations.payoutIntervals);
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getPersonnel(
        organizationId: string,
        includeHidden = true
      ): Promise<User[]> {
        try {
          const data: User[] = await api.get(
            endpoints.organizations.personnel,
            {
              includeHidden,
              organizationId,
            }
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async getStripeStatus(id: string) {
        try {
          const data: OrganizationStripeAccountStatusResponse = await api.get(
            stringUtil.replace(endpoints.organizations.accountStatus, {
              ':id': id,
            })
          );
          return data;
        } catch (error: unknown) {
          if (isInputError(error) && error.status === 400) {
            return undefined;
          }
          return Promise.reject({ error });
        }
      },

      async getStructure(
        organizationId: string
      ): Promise<OrganizationStructure> {
        try {
          const data: OrganizationStructure = await api.get(
            stringUtil.replace(endpoints.organizations.structure, {
              ':id': organizationId,
            })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },
      async getStructureV2(
        organizationId: string
      ): Promise<OrganizationStructureV2> {
        try {
          const data: OrganizationStructureV2 = await api.get(
            stringUtil.replace(endpoints.organizations.structurev2, {
              ':id': organizationId,
            })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async getTaxSettings(id: string): Promise<TaxSettings> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.organizations.taxSettings, {
              ':id': id,
            })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async setParent(
        id: string,
        parentId: string,
        type: OrganizationMemberType
      ): Promise<OrganizationResponse> {
        let endpoint = '';
        switch (type) {
          case 'lot':
            endpoint = stringUtil.replace(endpoints.lots.updateOrganization, {
              ':id': id,
            });
            break;

          case 'organization':
            endpoint = stringUtil.replace(
              endpoints.organizations.updateOrganization,
              {
                ':id': id,
              }
            );
            break;

          case 'user':
            endpoint = stringUtil.replace(
              endpoints.accounts.updateOrganization,
              {
                ':id': id,
              }
            );
            break;

          case 'vendor':
            endpoint = stringUtil.replace(
              endpoints.vendors.updateOrganization,
              {
                ':id': id,
              }
            );
            break;
        }

        try {
          const data: OrganizationResponse = await api.patch(endpoint, {
            parentId,
          });
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async updateContactDetails(
        type: 'Edit' | 'Create',
        id: string,
        request: BillingInfoRequest
      ) {
        try {
          if (type === 'Edit') {
            const data: OrganizationContactDetails = await api.putFile(
              stringUtil.replace(endpoints.organizations.contactDetails, {
                ':id': id,
              }),
              request
            );
            return data;
          } else {
            const data: OrganizationContactDetails = await api.postFile(
              stringUtil.replace(endpoints.organizations.contactDetails, {
                ':id': '',
              }),
              { organizationId: id, ...request }
            );
            return data;
          }
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async updateOrganization(
        id: string,
        request: OrganizationRequest
      ): Promise<OrganizationResponse> {
        try {
          const data: OrganizationResponse = await api.putFile(
            stringUtil.replace(endpoints.organizations.base, { ':id': id }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async updateOrderingSettings(
        id: string,
        request: OrderingSettingsRequest
      ) {
        try {
          return await api.put(
            stringUtil.replace(endpoints.organizations.orderingSettings, {
              ':id': id,
            }),
            request
          );
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },
    };
  }, [api]);
};
const isInputError = (
  error: unknown
): error is { message: string; status: number } => {
  return (
    !!error &&
    typeof error === 'object' &&
    'message' in error &&
    'status' in error
  );
};

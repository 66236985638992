import { IconButton } from '@mui/material';

import { styled } from '../../theme/stitches.config';

export const StyledBadge = styled('span', {
  backgroundColor: '$RedBrand',
  border: '2px solid $White',
  borderRadius: '50%',
  boxSizing: 'content-box',
  color: '$White',
  fontSizeRem: 10,
  fontWeight: 800,
  height: 16,
  lineHeightRem: 16,
  position: 'absolute',
  right: 1,
  top: 1,
  width: 16,
  zIndex: 5,
});

export const StyledIconButton = styled(IconButton, {
  '&.MuiIconButton-root': {
    '&[disabled] svg': {
      color: '$Neutral03',
    },
  },

  position: 'relative',
  defaultVariants: { type: 'secondary' },
  variants: {
    type: {
      primary: {
        '&.MuiIconButton-root': {
          backgroundColor: '$GreenBrand',
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: '$GreenBrand',
            opacity: 0.75,
          },
          '&.Mui-disabled': {
            backgroundColor: '$Neutral02',
            color: '$Neutral05',
            cursor: 'default',
            '&:hover': {
              opacity: 1,
            },
          },
        },
        svg: {
          color: '$White',
        },
      },
      secondary: {},
      outlined: {
        '&.MuiIconButton-root': {
          backgroundColor: '$White',
          borderColor: '$Neutral05',
          borderRadius: '16px',
          borderStyle: 'solid',
          borderWidth: '1px',
        },
      },
    },
  },
});

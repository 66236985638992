import debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { unique } from 'utils/list-util';

import { Text } from '@goodfynd/react-web.typography.text';
import {
  StyledFilterMenu,
  StyledFilterMenuToggle,
} from '@goodfynd/react-web.ui.filter-menu';
import { Icon } from '@goodfynd/react-web.ui.icon';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';

import {
  StyledMenuItem,
  StyledTextField,
  StyledTextFieldContainer,
} from './styles';

import type { AutocompleteRequest } from '../../types/search';
import type { FilterMenuOption } from '@goodfynd/react-web.ui.filter-menu';
import type { FilterMenuAutocompleteAsyncProps } from './types';
import type { IconName } from '@goodfynd/react-web.ui.icon';
export default function FilterMenuAutocompleteAsync({
  defaultOptions = [],
  getOptions,
  onChange,
  placeholder = 'Find...',
  value,
  ...props
}: FilterMenuAutocompleteAsyncProps) {
  const [anchor, setAnchor] = useState(null);
  const [options, setOptions] =
    useState<readonly FilterMenuOption[]>(defaultOptions);
  const open = Boolean(anchor);

  const handleClose = () => setAnchor(null);
  const handleMenuClick = (e: ClickButtonEvent) => {
    setAnchor(e.currentTarget);
  };

  const handleOptionSelect = (option: FilterMenuOption) => {
    onChange?.(option);
    handleClose();
  };

  const fetch = debounce(
    async (
      request: AutocompleteRequest,
      callback: (results?: readonly FilterMenuOption[]) => void
    ) => {
      let options;
      try {
        options = await getOptions(request);
      } catch (error) {
        console.debug('autocomplete error: ', error);
      } finally {
        callback(options);
      }
    },
    300
  );

  const selectedLabel = React.useMemo(
    () =>
      [...defaultOptions, ...options]
        .filter((option) =>
          Array.isArray(value)
            ? value.includes(option.value)
            : option.value === value
        )
        .map((option) => option.label)
        .filter(unique),
    [defaultOptions, options, value]
  );

  return (
    <>
      <StyledFilterMenuToggle
        id="search-filter-toggle"
        aria-controls="search-filter-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        display="filter"
        endIcon={<Icon name={open ? 'chevron_up' : 'chevron_down'} />}
        onBlur={() => setOptions(defaultOptions)}
        onClick={handleMenuClick}
        size="sm"
        {...props}
      >
        <Text type="body2">
          {selectedLabel
            ? `${props.label}: ${
                selectedLabel[1]
                  ? `${selectedLabel.length} selected`
                  : selectedLabel.join(', ')
              }`
            : props.label}
        </Text>
      </StyledFilterMenuToggle>

      <StyledFilterMenu
        id="search-filter-menu"
        anchorEl={anchor}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        elevation={0}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        MenuListProps={{
          'aria-labelledby': 'search-filter-toggle',
        }}
      >
        <StyledTextFieldContainer>
          <StyledTextField
            fullWidth
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              e.target.value
                ? fetch(
                    { query: e.target.value },
                    (results?: readonly FilterMenuOption[]) =>
                      setOptions(results || defaultOptions)
                  )
                : setOptions(defaultOptions)
            }
            placeholder={placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name="search" />
                </InputAdornment>
              ),
              type: 'search',
            }}
          />
        </StyledTextFieldContainer>

        <Divider />

        {options.map((option) => {
          const icon: IconName | undefined = option.isDeleted
            ? 'hide'
            : option.icon;
          return (
            <StyledMenuItem
              key={option.value}
              onClick={() => handleOptionSelect(option)}
            >
              <span>
                <span style={{ display: 'flex' }}>
                  {!!icon && <Icon name={icon} size={16} />}
                  {option.label}
                </span>
                <Text
                  css={{ color: '$Neutral07', fontWeight: 400 }}
                  type="subh3"
                >
                  {option.description}
                </Text>
              </span>

              {value?.includes(option.value) && <Icon name="check" />}
            </StyledMenuItem>
          );
        })}
      </StyledFilterMenu>
    </>
  );
}

import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import { LicenseListResponse, VendorResponse } from '../../services/api/types';
import useFetch from '../../services/api/useFetch';
import {
  VendorLicenseDeleteRequest,
  VendorLicenseRequest,
} from '../../stores/vendor-store/types';

export const useLicenseApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async getLicenses(): Promise<LicenseListResponse> {
        try {
          const data: LicenseListResponse = await api.get(
            endpoints.license.list
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async updateLicense(request: VendorLicenseRequest) {
        try {
          const data: VendorResponse = await api.patchFile(
            stringUtil.replace(endpoints.license.update, {
              ':vendorId': request.vendorId,
            }),
            { ...request, name: 'ignored', location: 'ignored' }
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async deleteLicense(request: VendorLicenseDeleteRequest) {
        try {
          const data: VendorResponse = await api.delete(
            stringUtil.replaceAll(endpoints.license.delete, {
              ':vendorId': request.vendorId,
              ':id': request.id,
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },
    };
  }, [api]);
};

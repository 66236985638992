import { useEffect } from 'react';

import { useApp } from '../../context/AppContext';
import { useAuth } from '../useAuth';
import { RequireAuthArgs } from './types';
import { userHasAccess } from './utils';
import { useRouter } from 'next/router';

export const useRequireAuth = (args?: RequireAuthArgs) => {
  const { getLoginUrl, isLoading } = useAuth();
  const { isLoggedIn, user, userIsLoading } = useApp();
  const router = useRouter();

  useEffect(() => {
    const { hasAccess, redirectUrl } = userHasAccess(
      userIsLoading || isLoading,
      isLoggedIn,
      user,
      getLoginUrl(),
      args?.allowedRoles
    );

    if (hasAccess == undefined || hasAccess || !redirectUrl) {
      return;
    }

    if (args?.authRequired) {
      redirectUrl.startsWith('/')
        ? router.replace(redirectUrl)
        : (window.location.href = redirectUrl as string);
    }
  }, [
    args?.allowedRoles,
    args?.authRequired,
    getLoginUrl,
    isLoggedIn,
    user,
    userIsLoading,
    isLoading,
    router,
  ]);

  return isLoggedIn;
};

import type { ColorName } from '@goodfynd/react-web.ui.icon';
import arraystat from 'arraystat';
import numbro from 'numbro';

export const asCurrency = (
  price: number | string,
  options: Partial<numbro.Format> = {}
) => {
  return Number(
    numbro(Number(price) || 0).format({
      mantissa: 2,
      output: 'number',
      spaceSeparated: false,
      thousandSeparated: false,
      ...options,
    })
  );
};

export const calculatePercentChange = (start: number, end: number) => {
  const sumDifference = end - start;
  const percentageDifference = start ? (sumDifference * 100) / start : 0;
  return percentageDifference;
};

export const getCurrency = (
  price: number | string,
  options: Partial<numbro.Format> = {}
) => {
  return numbro(Number(price) || 0).format({
    mantissa: 2,
    output: 'number',
    spaceSeparated: false,
    thousandSeparated: false,
    ...options,
  });
};

export const getDisplayColor = (value: number): ColorName => {
  if (value === 0) {
    return 'Neutral13';
  }

  if (value < 0) {
    return 'RedBrand';
  }

  return 'GreenBrand';
};

export const getStats = (
  data: number[]
): {
  avg: number;
  max: number;
  median: number;
  min: number;
  q1: number;
  q2: number;
  sum: number;
} => {
  return arraystat(data);
};

export const getStatsList = (data: number[]): number[] => {
  const { max, median, min, q1, q2 } = arraystat(data);
  return [min, q1, median, q2, max];
};

export const getTicketNumber = (
  orderTime: Date | null | string = null,
  ticketNumber: number | null = null
) => {
  if (ticketNumber) {
    return ticketNumber;
  }

  const time = new Date(orderTime || new Date().toString());
  const timeString = time.getTime().toString();
  const timeStringLength = timeString.length;
  return parseInt(
    timeString.substr(0, timeStringLength - 3).substr(timeStringLength - 3 - 3),
    10
  );
};

export const getWholeNumber = (amount: number | string) => {
  return Number(Number(amount).toFixed(0));
};

export const minutesToMS = (minutes: number) => minutes * 60000;

export const random = (min = 1, max = 10) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const renderNumber = (
  price: number | string,
  symbol: '$' | '¥' | '@' | false = false,
  { thousandSeparated = true, ...options }: Partial<numbro.Format> = {}
) => {
  return numbro(Number(price) || 0).format({
    currencySymbol: symbol || '',
    output: symbol ? 'currency' : 'number',
    spaceSeparated: false,
    thousandSeparated,
    ...options,
  });
};

export const renderPercent = (
  number: number | string,
  { thousandSeparated = true, ...options }: Partial<numbro.Format> = {}
) => {
  return numbro(Number(number) || 0).format({
    output: 'percent',
    spaceSeparated: false,
    thousandSeparated,
    ...options,
  });
};

export const renderPrice = (
  price: number | string,
  symbol: '$' | '¥' | '@' | false = '$',
  { thousandSeparated = true, ...options }: Partial<numbro.Format> = {}
) => {
  return numbro(Number(price) || 0).format({
    currencySymbol: symbol || '',
    mantissa: 2,
    output: symbol ? 'currency' : 'number',
    spaceSeparated: false,
    thousandSeparated,
    ...options,
  });
};

export function roundTo(num: number, digits: number) {
  const numToFixedDp = Number(num).toFixed(digits);
  return Number(numToFixedDp);
}

interface AmountDisplayOptions extends Partial<numbro.Format> {
  suffixMillions?: string;
  suffixThousands?: string;
}
export const getAmountDisplay = (
  value: number,
  {
    suffixMillions = 'M',
    suffixThousands = 'K',
    trimMantissa = true,
    ...options
  }: AmountDisplayOptions
) => {
  const size = Math.floor(value).toString().length;
  if (size > 8) {
    return (
      renderNumber(value / 1000000, false, { trimMantissa, ...options }) +
      suffixMillions
    );
  }

  if (size > 7) {
    return (
      renderNumber(value / 1000000, false, {
        mantissa: 1,
        trimMantissa,
        ...options,
      }) + suffixMillions
    );
  }

  if (size > 6) {
    return (
      renderNumber(value / 1000000, false, {
        mantissa: 2,
        trimMantissa,
        ...options,
      }) + suffixMillions
    );
  }

  if (size > 5) {
    return (
      renderNumber(value / 1000, false, {
        mantissa: 2,
        trimMantissa,
        ...options,
      }) + suffixThousands
    );
  }

  if (size > 3) {
    return (
      renderNumber(value / 1000, false, {
        mantissa: 2,
        trimMantissa,
        ...options,
      }) + suffixThousands
    );
  }

  return Math.floor(value).toString();
};

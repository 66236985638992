import { styled } from '@goodfynd/react-web.theme';
import { Text } from '@goodfynd/react-web.typography.text';

export const StyledContainer = styled('span', {
  alignItems: 'center',
  display: 'flex',
  gap: 16,
});

export const StyledLabelContainer = styled('span');

export const StyledText = styled(Text, {
  color: '$Neutral13 !important',
});

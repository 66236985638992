import React from 'react';

import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  NoSsr,
} from '@mui/material';
import type { OutlinedInputProps } from '@mui/material/OutlinedInput';
import OutlinedInput from '@mui/material/OutlinedInput';

import TextNumberCustom from '../InputNumber/TextNumberCustom';

interface PercentInputControlProps extends OutlinedInputProps {
  allowNegative?: number;
  helperText?: string;
}
export default React.forwardRef(function PercentInputControl(
  {
    allowNegative,
    error,
    fullWidth = true,
    helperText,
    ...props
  }: PercentInputControlProps,
  ref: any
) {
  return (
    <NoSsr>
      <FormControl error={error} fullWidth={fullWidth}>
        <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
        <OutlinedInput
          ref={ref}
          {...props}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          fullWidth={fullWidth}
          inputComponent={TextNumberCustom as any}
          inputProps={{
            allowNegative,
          }}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </NoSsr>
  );
});

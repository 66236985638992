import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { StyledContainer } from '../styles';
import InputMaskControl from './InputMaskControl';

import type { InputMaskProps } from './types';

export default function InputMask<T extends FieldValues>({
  control,
  css,
  helperText,
  label,
  mask,
  name,
}: InputMaskProps<T>) {
  return (
    <StyledContainer css={css}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputMaskControl
            error={error}
            fullWidth
            helperText={(error?.message as string) || helperText}
            label={label}
            mask={mask}
            name={name}
            onChange={onChange}
            value={value as string}
          />
        )}
      />
    </StyledContainer>
  );
}

import React from 'react';
import type { FieldValues, Path, PathValue } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { DateObject } from 'react-multi-date-picker';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import Calendar from '../../Calendar';
import { StyledContainer } from '../styles';

import type { InputCalendarProps } from './types';
export default function InputCalendar<T extends FieldValues = FieldValues>({
  control,
  css,
  helperText,
  inputProps,
  label,
  name,
}: InputCalendarProps<T>) {
  return (
    <StyledContainer>
      <Controller<T>
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth>
            {typeof label === 'string' ? (
              <FormLabel component="legend">{label}</FormLabel>
            ) : (
              label
            )}

            <Calendar
              css={css}
              multiple
              {...inputProps}
              onChange={(dates: DateObject[]) => {
                const selectedDates =
                  dates
                    ?.filter((date) => date.isValid)
                    .map((date) => date.toDate())
                    .sort((a, b) => a.getTime() - b.getTime()) || [];
                onChange(selectedDates as PathValue<T, Path<T>>);
              }}
              value={value}
            />
            <FormHelperText>{error?.message || helperText}</FormHelperText>
          </FormControl>
        )}
      />
    </StyledContainer>
  );
}

import React, { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { FormLabel, Slider } from '@mui/material';

import { StyledContainer } from '../styles';

import type { InputSliderProps } from '../types';

export default function InputSlider<T extends FieldValues>({
  defaultValue,
  name,
  control,
  inputProps,
  label,
  setValue,
}: InputSliderProps<T>) {
  const [sliderValue, setSliderValue] = useState<number>(defaultValue);

  useEffect(() => {
    if (sliderValue) setValue?.(name, sliderValue);
  }, [name, setValue, sliderValue]);

  const handleChange = (event: any, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  };

  return (
    <StyledContainer>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Slider
            valueLabelDisplay="auto"
            min={0}
            max={100}
            step={1}
            {...inputProps}
            {...field}
            value={sliderValue}
            onChange={handleChange}
          />
        )}
      />
    </StyledContainer>
  );
}

import { useVendor } from 'context/VendorContext';
import React from 'react';
import { Theme } from 'theme';

import { PageTitleBanner } from '@goodfynd/react-web.layout.page-title-banner';
import { LoadingTruck } from '@goodfynd/react-web.ui.loading';

import { useOrganization } from '../../../context/OrganizationContext';
import Footer from '../Footer';
import Header from '../Header';
import { StyledPageContainer } from './styles';

import type { LayoutWithHeaderProps } from './types';
import { useRequireAuth } from '@hooks/useRequireAuth';

export default function LayoutWithHeader({
  bannerContainerCss,
  children,
  showOrganizationName,
  showVendorName,
  authRequired,
  allowedRoles,
  ...props
}: LayoutWithHeaderProps): JSX.Element {
  const isLoggedIn = useRequireAuth({ allowedRoles, authRequired });
  const { isLoading: orgIsLoading, organization } = useOrganization();
  const { isLoading: vendorIsLoading, vendor } = useVendor();

  if (authRequired && !isLoggedIn) {
    return <></>;
  }

  return (
    <Theme>
      <StyledPageContainer css={{ paddingTop: 64 }}>
        <Header />
        <>
          {typeof props.bannerTitle === 'string' ? (
            <PageTitleBanner
              containerCss={bannerContainerCss}
              subtitle={
                showVendorName
                  ? vendor?.uniqueName
                  : showOrganizationName
                  ? organization.name
                  : props.bannerSubTitle
              }
              title={props.bannerTitle}
            />
          ) : (
            props.bannerTitle
          )}

          {orgIsLoading || vendorIsLoading ? <LoadingTruck /> : children}
        </>
        <Footer />
      </StyledPageContainer>
    </Theme>
  );
}

import React, { useCallback, useEffect } from 'react';
import type { FieldValues} from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { strings } from '@goodfynd/react-web.lib.strings';
import { Loading, LoadingTruck } from '@goodfynd/react-web.ui.loading';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from '../../Modal';
import { StyledButton, StyledButtonContainer, StyledContainer } from './styles';

import type { ModalFormProps } from './types';
export default function ModalForm<T extends FieldValues>({
  busy,
  defaultValues,
  onSubmit,
  otherActions,
  renderFields,
  submitButton,
  submitButtonDisabled,
  submitButtonDisplay,
  submitButtonStyle,
  submitButtonText = strings.labels.submit,
  validationSchema,
  resetOnDefaultChanges = false,
  ...props
}: ModalFormProps<T>) {
  const resolver = yupResolver(validationSchema ?? yup.object({}));
  const form = useForm<T>({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (resetOnDefaultChanges) {
      form.reset(defaultValues);
    }
  }, [defaultValues, form, resetOnDefaultChanges]);

  const renderSubmitButtonContent = useCallback(() => {
    if (busy) {
      return <Loading type="line" />;
    }

    if (typeof submitButtonText === 'string') {
      return submitButtonText;
    }

    return submitButtonText?.(form.watch);
  }, [busy, form.watch, submitButtonText]);

  return (
    <Modal
      footer={
        <StyledButtonContainer>
          {otherActions}

          {submitButton?.(form) || (
            <StyledButton
              id="btnSubmitModal"
              css={submitButtonStyle}
              disabled={busy || submitButtonDisabled}
              display={submitButtonDisplay}
              onClick={form.handleSubmit(onSubmit)}
            >
              {renderSubmitButtonContent()}
            </StyledButton>
          )}
        </StyledButtonContainer>
      }
      {...props}
    >
      <StyledContainer>
        {busy ? <LoadingTruck /> : renderFields(form)}
      </StyledContainer>
    </Modal>
  );
}

import { useCallback } from 'react';

import type { OptionalObjectSchema } from 'yup/lib/object';

export function useYupValidationResolver(
  validationSchema?: OptionalObjectSchema<any>
) {
  return useCallback(
    async (data) => {
      try {
        const values = await validationSchema?.validate(data, {
          abortEarly: false,
        });

        return {
          values: values || {},
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner?.reduce(
            (allErrors: object, currentError: any) => ({
              ...allErrors,
              [convertArrayPath(currentError.path)]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );
}

const convertArrayPath = (path: string) => {
  // converts  item[0].name to item.0.name
  if (!path.includes('[') || !path.includes(']')) return path;
  return path.replaceAll(/\[(\d+)]./g, '.$1.');
};

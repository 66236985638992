import { styled } from '@goodfynd/react-web.theme';
import Button from '@goodfynd/react-web.ui.button';

export const StyledContainer = styled('div', {
  padding: 24,
});

export const StyledButtonContainer = styled('div', {
  height: '100%',
  display: 'flex',
  gap: 32,
  justifyContent: 'center',
  padding: 16,
});

export const StyledButton = styled(Button, {
  width: 300,
});

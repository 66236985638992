export const PICKUP_CAPACITY_OPTIONS: InputOption[] = [
  { value: 0 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
  { value: 50 },
  { value: 75 },
  { value: 80 },
  { value: 90 },
  { value: 100 },
  { value: 125 },
  { value: 250 },
];

import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';

import { Theme as GoodfyndTheme } from '@goodfynd/react-web.theme';

import { useStores } from '../stores/root-store/root-store-context';

export const Theme = observer(function Theme({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  const { isDarkMode } = useStores();
  return <GoodfyndTheme isDarkMode={isDarkMode}>{children}</GoodfyndTheme>;
});

import Button from '@goodfynd/react-web.ui.button';

import { styled } from '../../theme/stitches.config';

export const StyledButton = styled(Button, {
  display: 'inline',
  '&.MuiButton-root': {
    borderRadius: 4,
    boxShadow: 'none',
  },
  '+ button': {
    marginLeft: 12,
  },
});

export const StyledIconButton = styled(Button, {
  '&.MuiButton-root': {},
});

import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { StyledContainer } from '../styles';
import type { InputProps } from '../types';
import InputPhoneControl from './InputPhoneControl';

export default function InputPhone<
  T extends FieldValues = Dictionary<string, string>
>({ control, css, helperText, label, name }: InputProps<T>) {
  return (
    <StyledContainer css={css}>
      <Controller<T>
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputPhoneControl
            error={!!error}
            fullWidth
            helperText={(error?.message as string) || helperText}
            label={label}
            onChange={onChange}
            value={value}
          />
        )}
      />
    </StyledContainer>
  );
}

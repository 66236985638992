import React from 'react';

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { StyledText } from '@goodfynd/react-web.typography.text';

import config from '../../../config';
import type { LandingPageHeadlineFormProps } from '../types';
import {
    StyledContainer, StyledContent, StyledFormContainer, StyledHeadLine, StyledHeadLineContainer,
    StyledImage, StyledMobileImage, StyledOverlay
} from './styles';

const LandingPageHeadlineForm = ({
  desktop_image,
  headline,
  sub_headline,
  form_headline,
  form_sub_headline,
  mobile_image,
  hubspot_form_id = '',
}: LandingPageHeadlineFormProps): JSX.Element => {
  const portalId = config.env.HUBSPOT_PORTAL_ID || '';
  const hubspotFormShortId = hubspot_form_id.replaceAll('-', '');
  const formElementId = `hubSpotForm_${hubspotFormShortId}`;
  useHubspotForm({
    portalId: portalId,
    formId: hubspot_form_id,
    target: `#${formElementId}`,
  });
  return (
    <StyledContainer css={{}}>
      <StyledImage css={{ backgroundImage: `url(${desktop_image})` }} />
      <StyledMobileImage css={{ backgroundImage: `url(${mobile_image})` }} />

      <StyledOverlay>
        <StyledHeadLineContainer>
          <div>
            <StyledHeadLine inherit type="h2">
              {headline}
            </StyledHeadLine>
            <StyledHeadLine inherit>{sub_headline}</StyledHeadLine>
          </div>
        </StyledHeadLineContainer>
      </StyledOverlay>
      <StyledContent>
        <StyledFormContainer>
          <StyledText type="h3">{form_headline}</StyledText>
          <StyledText type="body1">{form_sub_headline}</StyledText>
          <div id={formElementId}></div>
        </StyledFormContainer>
      </StyledContent>
    </StyledContainer>
  );
};

export default LandingPageHeadlineForm;

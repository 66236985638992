import type { InputBaseComponentProps } from '@mui/material';
import type { PropsWithChildren } from 'react';
import React from 'react';
import type { Props } from 'react-input-mask';
import InputMask from 'react-input-mask';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(
  { ...props }: PropsWithChildren<InputBaseComponentProps & Props>,
  ref: React.LegacyRef<InputMask>
) {
  return <InputMask ref={ref} {...props} />;
});
export default TextMaskCustom;

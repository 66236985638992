import truncate from 'lodash/truncate';
import React, { useMemo, useState } from 'react';

import { Text } from '@goodfynd/react-web.typography.text';
import {
  StyledFilterMenu,
  StyledFilterMenuToggle,
} from '@goodfynd/react-web.ui.filter-menu';
import { Icon } from '@goodfynd/react-web.ui.icon';
import { Loading } from '@goodfynd/react-web.ui.loading';

import { StyledMenu, StyledMenuItem } from './styles';

import type { FilterMenuAutocompleteProps } from './types';

import type { IconName } from '@goodfynd/react-web.ui.icon';
export default function FilterMenuAutocomplete({
  busy,
  onChange,
  options,
  showCounts = true,
  value,
  ...props
}: Omit<FilterMenuAutocompleteProps, 'placeholder'>) {
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleClose = () => setAnchor(null);
  const handleMenuClick = (e: ClickButtonEvent) => {
    setAnchor(e.currentTarget);
  };

  const selectedLabel = useMemo(
    () =>
      Array.isArray(value)
        ? value.map((option) => option.label).join(', ')
        : value?.label,
    [value]
  );
  const selectedValues = useMemo(
    () =>
      Array.isArray(value)
        ? value.map((option) => option.value)
        : value
        ? [value.value]
        : [],
    [value]
  );

  return (
    <>
      <StyledFilterMenuToggle
        id="search-filter-toggle"
        aria-controls="search-filter-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        endIcon={<Icon name={open ? 'chevron_up' : 'chevron_down'} />}
        onClick={handleMenuClick}
        size="sm"
        display="filter"
        {...props}
      >
        {busy ? (
          <Loading type="line" />
        ) : (
          <Text type="body2">
            {selectedLabel
              ? `${props.label}: ${truncate(selectedLabel, { length: 15 })}`
              : props.label}
          </Text>
        )}
      </StyledFilterMenuToggle>

      <StyledFilterMenu
        id="search-filter-menu"
        anchorEl={anchor}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        elevation={0}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        MenuListProps={{
          'aria-labelledby': 'search-filter-toggle',
        }}
      >
        <StyledMenu>
          {options.map((option) => {
            const icon: IconName | undefined = option.isDeleted
              ? 'hide'
              : option.icon;
            return (
              <StyledMenuItem
                key={option.value}
                onClick={() => onChange(option)}
              >
                <span>
                  <span style={{ display: 'flex' }}>
                    {!!icon && <Icon name={icon} size={16} />}
                    {option.label}
                  </span>
                  <Text
                    css={{ color: '$Neutral07', fontWeight: 400 }}
                    type="subh3"
                  >
                    {option.description}
                  </Text>
                </span>

                {selectedValues.includes(option.value) ? (
                  <Icon name="check" />
                ) : (
                  Number(option.count) > 0 &&
                  showCounts && <Text type="caption">{option.count}</Text>
                )}
              </StyledMenuItem>
            );
          })}
        </StyledMenu>
      </StyledFilterMenu>
    </>
  );
}

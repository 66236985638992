import Button from '@goodfynd/react-web.ui.button';

import { styled } from '../../theme/stitches.config';

export const StyledIconButton = styled(Button, {
  '&.MuiButton-root': {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    zIndex: 1,
  },
});
export const StyledBody = styled('div', {
  // position: 'relative',
});

export const StyledContainer = styled('div', {
  backgroundColor: '$White',
  borderRadius: 16,
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  left: '50%',
  maxHeight: 'calc(100% - 128px)',
  minWidth: 500,
  outline: 'none',
  overflow: 'hidden',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  '@sm': {
    height: 'calc(100% - 8px)',
    maxHeight: 'calc(100% - 8px)',
    minWidth: 'calc(100% - 8px)',
    width: 'calc(100% - 8px) !important',
  },
});

export const StyledContent = styled('div', {
  height: 'auto',
  maxHeight: 'calc(100vh - 225px)',
  overflowY: 'auto',
  position: 'relative',
  '@sm': {
    maxHeight: 'calc(100vh - 88px)',
    minWidth: 'calc(100% - 16px)',
  },
  '@lg': {
    // maxHeight: '90%',
  },
  paddingBottom: 88,
});

export const StyledFooter = styled('div', {
  backgroundColor: '$White',
  bottom: 0,
  minHeight: 88,
  left: 0,
  position: 'absolute',
  shadow: 2,
  width: '100%',
  zIndex: 1,
  '@sm': {
    minHeight: 72,
  },
});

export const StyledHeader = styled('div', {
  backgroundColor: '$White',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  marginTop: 8,
  padding: 16,
  paddingLeft: 24,
  paddingRight: 72,
});

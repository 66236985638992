import 'cropperjs/dist/cropper.css';

import { styled } from '@goodfynd/react-web.theme';

export const StyledButtonContainer = styled('div', {
  display: 'grid',
  justifyContent: 'center',
});

export const StyledContainer = styled('div', {
  display: 'grid',
  padding: '20px',
  backgroundColor: '$White',
  rowGap: '16px',
  gridTemplateRows: 'auto min-content',
});

import { styled } from '@goodfynd/react-web.theme';
import { StyledText } from '@goodfynd/react-web.typography.text';
import Button from '@goodfynd/react-web.ui.button';
import { Paper } from '@mui/material';

import Container from '../../layout/Container';

export const StyledContainer = styled('div', {
  width: '100%',
  minHeight: 600,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  '@sm': {
    flexDirection: 'column',
  },
});

export const StyledContent = styled(Container, {
  '&.MuiContainer-root': {
    width: '100%',
    display: 'flex',
    backgroundColor: 'inherit',
    zIndex: 3,
    alignItems: 'flex-start',
    flexDirection: 'row-reverse',
    '@sm': {
      alignItems: 'flex-start',
      padding: 0,
    },
  },
});

export const StyledOverlay = styled('div', {
  background: '$Black',
  opacity: 0.6,
  minHeight: '33%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'flex-start',
  left: 0,
  right: 0,
  zIndex: 2,
  color: '$White',
  '@md': {
    bottom: 0,
  },
  '@sm': {
    color: '$Black',
    alignItems: 'flex-start',
    top: '20%',
    left: 16,
    right: 16,
    padding: '24px 0',
    background: '$White',
    opacity: 1,
    zIndex: 9,
    minHeight: 150,
    bottom: undefined,
    boxShadow:
      '0px 4px 20px rgba(0, 0, 0, 0.05), 0px 2px 12px rgba(0, 0, 0, 0.05)',
    borderRadius: 8,
  },
});
export const StyledImage = styled('div', {
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  position: 'absolute',
  zIndex: 0,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '@sm': {
    display: 'none',
  },
});
export const StyledMobileImage = styled('div', {
  display: 'none',
  height: '367px',
  zIndex: 0,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '@sm': {
    display: 'block',
  },
});

export const StyledFormContainer = styled(Paper, {
  maxWidth: '50%',
  '@md': {
    minWidth: '475px',
  },
  minHeight: '584px',
  padding: 32,
  zIndex: 3,
  margin: '50px 0px',
  '@sm': {
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
    maxWidth: '100%',
    minWidth: '375px',
    width: '100%',
    height: 'fit-content',
    margin: '33% 0px',
  },
});
export const StyledHeadLineContainer = styled(Container, {
  '&.MuiContainer-root': {
    display: 'grid',
    gridTemplateColumns: '50% auto',
    backgroundColor: 'inherit',
  },
  '@sm': {
    '&.MuiContainer-root': {
      gridTemplateColumns: 'auto',
    },
  },
});
export const StyledHeadLine = styled(StyledText, {});

export const StyledSubmitButton = styled(Button, {
  '@lg': {
    width: '230px',
  },
  '@sm': {
    width: '100%',
  },
});

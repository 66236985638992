import React from 'react';

import { StyledContainer } from './styles';

import type { ContainerProps } from './types';

export default function Container({
  paddingBottom,
  paddingTop,
  ...props
}: ContainerProps): JSX.Element {
  return (
    <StyledContainer
      css={{
        paddingBottom,
        paddingTop,
      }}
      maxWidth="xl"
      {...props}
    />
  );
}

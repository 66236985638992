import type { ProfileStatus } from '@goodfynd/react-web.lib.dashboard';
import type { Suggestion } from 'types/shared';

export const getVendorSuggestionStatus = (
  suggestion: Suggestion
): ProfileStatus => {
  return suggestion.isDeleted
    ? 'no-profiles'
    : suggestion.orderingEnabled
    ? 'online'
    : 'offline';
};

import { observer } from 'mobx-react-lite';
import router from 'next/router';
import React, { createContext, useMemo, useRef, useState } from 'react';

import routes from '@config/routes';

import { useUser } from '../../hooks';
import { useAuth } from '../../hooks/useAuth';
import { useStores } from '../../stores';

import type { UserInfo } from '../../stores/models/user';

import type {
  AppContextValue,
  AppDispatchContextValue,
  AppProviderProps,
  UserProfiles,
} from './types';

export const AppContext = createContext<AppContextValue | undefined>(undefined);
export const AppDispatchContext = createContext<
  AppDispatchContextValue | undefined
>(undefined);

export default observer(function AppProvider({ children }: AppProviderProps) {
  const { isDarkMode = false, toggleDarkMode } = useStores();

  const [vendorNamesById, setVendorNamesById] = useState<
    Record<string, string>
  >({});

  const defaultUserValue = useRef<UserInfo>({
    isAuthenticated: undefined,
  } as UserInfo);
  const [user, setUser] = useState<UserInfo>(defaultUserValue.current);
  const notFound = () => {
    router.push(routes.account.profile);
  };
  const {
    isFetched: userIsFetched,
    isLoading: userIsLoading,
    profileNames,
    profileRecords,
    profiles,
    refetch: refreshAuth,
  } = useUser({ setUser, notFound });

  const {
    getLoginUrl,
    isAuthenticated,
    isLoading: identityLoading,
    logout,
    user: identity,
  } = useAuth();

  const value = useMemo(
    () => ({
      identity,
      identityLoading,
      isDarkMode,
      isLoggedIn: isAuthenticated,
      profileRecords,
      profiles: profiles || ([] as unknown as UserProfiles),
      user,
      userIsFetched,
      userIsLoading,
      vendorNamesById: {
        ...profileNames,
        ...vendorNamesById,
      },
    }),
    [
      identity,
      identityLoading,
      isAuthenticated,
      isDarkMode,
      profileNames,
      profileRecords,
      profiles,
      user,
      userIsFetched,
      userIsLoading,
      vendorNamesById,
    ]
  );

  const dispatchValue = useMemo(
    () => ({
      logout,
      getLoginUrl,
      refreshAuth,
      setUser,
      setVendorNamesById,
      toggleDarkMode,
    }),
    [getLoginUrl, logout, refreshAuth, toggleDarkMode]
  );

  return (
    <AppContext.Provider value={value}>
      <AppDispatchContext.Provider value={dispatchValue}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
});

import type { UserInfo } from 'stores/models/user';
import { UserRole } from 'types/enums';
import {
  getDashboardRoutePath,
  getOrganizationRoutePath,
} from 'utils/routes-util';

import routes from '@config/routes';

import config from '../../../config';
import { hasRoles } from 'utils/users-util';

export const MAIN_NAV = (
  { defaultVendorId = '', isVendorOwner, roles }: UserInfo,
  profileId?: string,
  isAuthenticated?: boolean
) =>
  [
    {
      hide: !isVendorOwner || !isAuthenticated,
      href: config.routes.docs,
      label: 'Help Center',
    },
    {
      hide:
        !isAuthenticated ||
        !hasRoles(roles, [
          UserRole.Admin,
          UserRole.Intern,
          UserRole.VendorOwner,
          UserRole.VendorManager,
          UserRole.VendorEmployee,
          UserRole.SuperAdmin,
          UserRole.OrganizationOwner,
          UserRole.OrganizationManager,
          UserRole.OrganizationAccountant,
        ]),
      href: getDashboardRoutePath(
        routes.dashboard.overview,
        profileId || defaultVendorId
      ),
      label: 'Dashboard',
    },
    {
      hide: !hasRoles(roles, [
        UserRole.Admin,
        UserRole.Intern,
        UserRole.SuperAdmin,
      ]),
      href: 'https://admins.goodfynd.com',
      label: 'Admins Dashboard',
    },
    {
      hide: !hasRoles(roles, [UserRole.SuperAdmin]),
      href: getOrganizationRoutePath(
        routes.organization.preview,
        profileId ?? 'profile'
      ),
      label: 'Dashboard Preview',
    },
    {
      hide: isAuthenticated,
      href: config.routes.account.signIn,
      label: 'Log in',
    },
    {
      hide: isAuthenticated,
      href: config.routes.account.signUp,
      label: 'Sign up',
    },

    {
      hide: !config.app.mode.isDevelopment,
      href: '/ui',
      label: 'UI',
      links: [
        {
          href: '/ui/buttons',
          label: 'Buttons',
        },
      ],
    },
  ].filter((item) => !item.hide);

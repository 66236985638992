import { styled } from '@goodfynd/react-web.theme';
import { StyledMenuItem as StyledMenuItemBase } from '@goodfynd/react-web.ui.filter-menu';
import TextField from '@mui/material/TextField';

export const StyledMenu = styled('ul', {
  paddingLeft: 0,
});

export const StyledMenuItem = styled(StyledMenuItemBase, {
  alignItems: 'center',
  svg: {
    margin: '0 4px 0 0 !important',
  },
});

export const StyledTextField = styled(TextField, {
  '&.MuiTextField-root': {
    input: {
      paddingLeft: 0,
    },
  },
});

export const StyledTextFieldContainer = styled('aside', {
  padding: 16,
  paddingBottom: 24,
});

import React from 'react';
import TextTruncate from 'react-text-truncate';

import { Container } from '@goodfynd/react-web.layout.container';
import Text from '@goodfynd/react-web.typography.text';
import { Link } from '@goodfynd/react-web.ui.link';

import { StyledContainer, StyledContent } from './styles';

export default function CmsHelpSection({
  title,
  topics = [],
  ...props
}: CmsHelpSectionProps) {
  return (
    <Container css={{ paddingBottom: 24, paddingTop: 24 }}>
      {!!title && (
        <Text as="h3" marginBottom={24} type="h3">
          {title}
        </Text>
      )}

      <StyledContainer {...props}>
        {topics.map(({ featured_articles, description, title = '' }, index) => (
          <StyledContent key={title + index}>
            {!!title && (
              <Text as="h5" marginBottom={8} type="h5">
                {title}
              </Text>
            )}

            {!!description && (
              <Text marginBottom={16} type="body1">
                <TextTruncate element="span" line={2} text={description} />
              </Text>
            )}

            <ul>
              {featured_articles.map(({ name, slug }) => (
                <li key={slug}>
                  <Link
                    href={slug?.startsWith('http') ? slug : `/docs/${slug}`}
                  >
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </StyledContent>
        ))}
      </StyledContainer>
    </Container>
  );
}

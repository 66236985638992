import React, { useCallback } from 'react';
import type { FieldValues, Path, PathValue } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { StyledText } from '@goodfynd/react-web.styles';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import TextMaskCustom from '../InputMask/TextMaskCustom';
import { StyledContainer, StyledFormFieldRow } from '../styles';
import { defaultCreateOption, isInputOption } from './helpers';

import type { InputComboBoxProps } from '../types';
export default function InputComboBox<T extends FieldValues>({
  css,
  control,
  defaultValue,
  disabled,
  fullWidth = true,
  helperText,
  inputMode,
  inputRightNode,
  label,
  name,
  otherProps,
  options,
  onCreateOption = defaultCreateOption,
  freeSolo = false,
  groupBy,
  mask = '',
  ...props
}: InputComboBoxProps<T>) {
  const optionForLabel = (label: string): InputOption => {
    return options.find((o) => o.label === label) ?? onCreateOption(label);
  };

  const renderOption = useCallback(
    (attributes: React.HTMLAttributes<HTMLLIElement>, option: InputOption) => {
      const label =
        typeof option === 'string'
          ? option
          : typeof option.label === 'string' && option.label != ''
          ? option.label
          : option.value?.toString();
      return (
        <li {...attributes}>
          <span>
            <span>{label}</span>
            <StyledText
              css={{ color: '$Neutral07', fontWeight: 400 }}
              type="subh3"
            >
              {option.description ?? option.subLabel}
            </StyledText>
          </span>
        </li>
      );
    },
    []
  );

  return (
    <StyledContainer css={css} {...props}>
      <Controller<T>
        name={name}
        control={control}
        defaultValue={
          (options.find((option) => option.value === defaultValue) as any) || {
            label: '',
            value: undefined,
          }
        }
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          const handleInput = (
            event: React.SyntheticEvent<Element, Event>,
            value: InputOption | string | null | any[]
          ) => {
            if (!event) {
            } else if (!value) {
              onChange(null as PathValue<T, Path<T>>);
            } else if (event?.type == 'blur' && typeof value === 'string') {
              onChange(optionForLabel(value) as PathValue<T, Path<T>>);
            } else if (isInputOption(value)) {
              onChange(value as PathValue<T, Path<T>>);
            }
          };
          return (
            <>
              <StyledFormFieldRow css={{ alignItems: 'flex-end' }}>
                <Autocomplete<InputOption, boolean, boolean, boolean>
                  value={value as InputOption}
                  autoSelect
                  freeSolo={freeSolo}
                  fullWidth={fullWidth}
                  onInputChange={handleInput}
                  onChange={handleInput}
                  selectOnFocus
                  clearOnBlur
                  disabled={disabled}
                  groupBy={groupBy}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }

                    return typeof option.label === 'string'
                      ? option.label
                      : option.value?.toString();
                  }}
                  renderOption={renderOption}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          ...otherProps,
                          endAdornment:
                            otherProps?.endAdornment ??
                            params.InputProps.endAdornment,
                          inputComponent: TextMaskCustom as any,
                          inputProps: {
                            ...params.inputProps,
                            inputMode,
                            mask,
                            maskPlaceholder: null,
                          },
                        }}
                        label={label}
                        variant="outlined"
                        helperText={helperText}
                      />
                    );
                  }}
                  options={options}
                />
                {inputRightNode}
              </StyledFormFieldRow>

              {error?.message && (
                <FormHelperText error style={{ marginLeft: 0 }}>
                  {error.message}
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </StyledContainer>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { renderCmsBodyComponent } from 'utils/cms-util';

import { LoadingTruck } from '@goodfynd/react-web.ui.loading';
import { Box } from '@mui/material';

import CmsApi from '../../../services/api/cms-api';

import type { CmsPageContentProps } from './types';

export default function CmsPageContent({
  containerCSS,
  render,
  slug,
}: CmsPageContentProps): JSX.Element {
  const [body, setBody] = useState([]);
  const [busy, toggleBusy] = useState(true);

  useEffect(() => {
    if (slug) {
      (async () => {
        try {
          toggleBusy(true);
          const api = new CmsApi();
          const data = await api.getPageData(slug);
          const { body } = data?.fields || {};
          setBody(body);
        } finally {
          toggleBusy(false);
        }
      })();
    }
  }, [slug]);

  const renderBody = useCallback(
    () => (
      <>
        {body?.map(({ fields, type }, index) =>
          (render || renderCmsBodyComponent)(index, type, fields, {
            containerCSS,
          })
        )}
      </>
    ),
    [body, containerCSS, render]
  );

  return busy ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <LoadingTruck />
    </Box>
  ) : (
    renderBody()
  );
}

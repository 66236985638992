import { useApp } from '@context/AppContext';
import { UserRole } from 'types/enums';
import { hasRoles } from '@utils/users-util';
import { useMemo } from 'react';

export const useHasPermissions = (roles: UserRole[]) => {
  const { user, userIsFetched } = useApp();
  return useMemo(
    () => userIsFetched && hasRoles(user.roles, roles),
    [roles, user.roles, userIsFetched]
  );
};

export const usePermissions = () => {
  return {
    isRestrictedAdmin: useHasPermissions([UserRole.Admin, UserRole.SuperAdmin]),
    isVendorEmployee: useHasPermissions([
      UserRole.Admin,
      UserRole.SuperAdmin,
      UserRole.VendorEmployee,
      UserRole.VendorManager,
      UserRole.VendorOwner,
    ]),
    isVendorManager: useHasPermissions([
      UserRole.Admin,
      UserRole.SuperAdmin,
      UserRole.VendorManager,
      UserRole.VendorOwner,
      UserRole.OrganizationOwner,
      UserRole.OrganizationManager,
    ]),
    isVendorOwner: useHasPermissions([
      UserRole.Admin,
      UserRole.SuperAdmin,
      UserRole.VendorOwner,
      UserRole.OrganizationOwner,
      UserRole.OrganizationManager,
    ]),
    isOrganizationManager: useHasPermissions([
      UserRole.Admin,
      UserRole.SuperAdmin,
      UserRole.OrganizationOwner,
      UserRole.OrganizationManager,
    ]),
    isOrganizationOwner: useHasPermissions([
      UserRole.Admin,
      UserRole.SuperAdmin,
      UserRole.OrganizationOwner,
    ]),
  };
};

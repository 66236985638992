import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  getDashboardRoutePath,
  getOrganizationRoutePath,
} from 'utils/routes-util';

import { useProfileDispatch } from '@context/ProfileContext';
import type { ProfileContextValue } from '@context/ProfileContext/types';
import { ProfileSwitcher as ProfileSwitcherReusable } from '@goodfynd/react-web.dashboard.profile-switcher';

import env from '../../../config/env';
import routes from '../../../config/routes';
import { useOrganization } from '../../../context/OrganizationContext';
import { useVendor } from '../../../context/VendorContext';
import { AutocompleteVendorOrganizations } from '../AutocompleteVendorOrganizations';

import type { FilterMenuOption } from '@goodfynd/react-web.ui.filter-menu';
import type {
  ProfileStatus,
  ProfileSwitcherMethods,
  ProfileSwitcherProps as ProfileSwitcherReusableProps,
} from '@goodfynd/react-web.dashboard.profile-switcher';
export default function ProfileSwitcher({
  isOrganization,
  ...props
}: Partial<ProfileSwitcherReusableProps>) {
  const ref = useRef<ProfileSwitcherMethods>(null);

  const { push } = useRouter();

  const {
    isFetched: orgIsFetched,
    isLoading: orgIsLoading,
    organization,
  } = useOrganization();
  const {
    isFetched: vendorIsFetched,
    isLoading: vendorIsLoading,
    vendor,
    merchant,
  } = useVendor();
  const { setProfile } = useProfileDispatch();
  const isFetched = useMemo(
    () => orgIsFetched || vendorIsFetched,
    [orgIsFetched, vendorIsFetched]
  );
  const isLoading = useMemo(
    () => orgIsLoading || vendorIsLoading,
    [orgIsLoading, vendorIsLoading]
  );

  const profileName = useMemo(
    () =>
      isOrganization ? organization.name : vendor?.uniqueName || vendor?.name,

    [isOrganization, organization.name, vendor?.name, vendor?.uniqueName]
  );

  useEffect(() => {
    setProfile((profile) => ({
      ...profile,
      type: isOrganization ? 'organization' : 'vendor',
      nameId: isOrganization ? organization.nameId : vendor?.nameId,
    }));
  }, [isOrganization, organization.nameId, setProfile, vendor?.nameId]);

  const status: ProfileStatus | null = useMemo(() => {
    if (isOrganization) {
      return 'organization';
    }

    if (vendor?.isDeleted) {
      return 'no-profiles';
    }

    if (merchant.orderingEnabled) {
      return 'online';
    }

    return 'offline';
  }, [isOrganization, merchant, vendor]);

  const handleSelect = useCallback(
    (id, type) => {
      switch (type) {
        case 'lot':
          window.open(`${env.EVENT_APP_HOST}/dashboard/${id}/overview`);
          break;

        case 'organization':
          push(getOrganizationRoutePath(routes.organization.overview, id));
          break;

        case 'vendor':
          push(getDashboardRoutePath(routes.dashboard.overview, id));
          break;

        default:
          break;
      }
    },
    [push]
  );

  return (
    <ProfileSwitcherReusable
      autocompleteComponent={
        <AutocompleteVendorOrganizations
          css={{ paddingBottom: 0, paddingTop: 24 }}
          onChange={(option: FilterMenuOption) => {
            setProfile(option as ProfileContextValue);
            handleSelect(option.nameId || option.value, option.type);
            ref.current?.toggleModal?.(false);
          }}
        />
      }
      isLoading={!isFetched || isLoading}
      isOrganization={isOrganization}
      onSelect={handleSelect}
      profileName={profileName}
      ref={ref}
      status={status}
      {...props}
    />
  );
}

import { useVendor } from 'context/VendorContext';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { Theme } from 'theme';

import AuthFallback from '@components/auth/AuthFallback';
import { useApp } from '@context/AppContext';
import { SIDE_NAV_WIDTH } from '@goodfynd/react-web.layout.side-nav';
import { LoadingTruck } from '@goodfynd/react-web.ui.loading';
import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';

import { useRequireAuth } from '../../../hooks/useRequireAuth';
import { SideNav } from '../SideNav';
import { StyledBody } from './styles';

export default function Layout({ children, fullWidth }: LayoutProps) {
  useRequireAuth();
  const { isLoggedIn, userIsLoading } = useApp();
  const { isReady } = useRouter();

  const { isLoading } = useVendor();

  const renderPage = useCallback(() => {
    if (userIsLoading || isLoading !== false || !isReady) {
      return <LoadingTruck />;
    }

    if (!isLoggedIn) {
      return <AuthFallback />;
    }

    return children;
  }, [children, isLoading, isLoggedIn, isReady, userIsLoading]);

  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>

      <Theme>
        <NoSsr>
          <Box sx={{ display: 'flex' }}>
            <SideNav />
            <Box
              component="main"
              sx={{
                height: '100%',
                width: fullWidth
                  ? '100%'
                  : {
                      sm: `calc(1440px - ${SIDE_NAV_WIDTH}px + 32px)`,
                      xs: '100%',
                    },
              }}
            >
              <StyledBody>{renderPage()}</StyledBody>
            </Box>
          </Box>
        </NoSsr>
      </Theme>
    </>
  );
}

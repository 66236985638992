import React from 'react';

import Icon from '@goodfynd/react-web.ui.icon';

import { StyledBadge, StyledIconButton } from './styles';
import type { IconButtonProps } from './types';

export default function IconButton({
  badgeLabel,
  badgeStyle,
  onClick,
  icon,
  iconColor,
  iconSize,
  type,
  ...props
}: IconButtonProps) {
  return (
    <StyledIconButton {...props} type={type} onClick={onClick}>
      {badgeLabel && <StyledBadge css={badgeStyle}>{badgeLabel}</StyledBadge>}
      <Icon color={iconColor} name={icon} size={iconSize} />
    </StyledIconButton>
  );
}

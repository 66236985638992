import { createContext, useState } from 'react';
import {
  SearchContextValue,
  SearchDispatchContextValue,
  SearchProviderProps,
} from './types';

const initialSearchContextValue = {
  searchText: '',
};

export const SearchContext = createContext<SearchContextValue>(
  initialSearchContextValue
);

export const SearchDispatchContext = createContext<
  SearchDispatchContextValue | undefined
>(undefined);

const SearchProvider = ({ children }: SearchProviderProps) => {
  const [searchText, setSearchText] = useState<string>('');

  return (
    <SearchContext.Provider value={{ searchText }}>
      <SearchDispatchContext.Provider value={{ setSearchText }}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchContext.Provider>
  );
};

export default SearchProvider;

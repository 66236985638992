import { observer } from 'mobx-react-lite';
import React from 'react';

import { useVendor } from '@context/VendorContext';
import type {
  HeaderProps} from '@goodfynd/react-web.layout.header';
import {
  Header as FyndHeader
} from '@goodfynd/react-web.layout.header';

import config from '../../../config';
import { useApp, useAppDispatch } from '../../../context/AppContext';
import { useStores } from '../../../stores';
import { MAIN_NAV } from './header-links';

export default observer(function Header(props: Partial<HeaderProps>) {
  const { isDarkMode = false, toggleDarkMode } = useStores();
  const { user, isLoggedIn } = useApp();
  const { getLoginUrl, logout } = useAppDispatch();
  const { vendorId } = useVendor();

  const navLinks = props.navLinks || MAIN_NAV(user, vendorId, isLoggedIn);

  return (
    <FyndHeader
      {...props}
      isAuthenticated={isLoggedIn}
      userFullName={user.fullName}
      userInitials={user.initials?.replace(' ', '')}
      authenticated_navigation={[]}
      getLoginUrl={getLoginUrl}
      isDarkMode={isDarkMode}
      logoEnvironment={config.env.NAME_LOWER}
      logout={() => {
        logout();
      }}
      logoType="vendors"
      navLinks={navLinks}
      theme="white"
      toggleDarkMode={toggleDarkMode}
      unauthenticated_navigation={[]}
    />
  );
});

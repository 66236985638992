import React from 'react';
import InputMask from 'react-input-mask';

import { FormControl, FormHelperText, InputLabel, NoSsr } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

import type { InputPhoneControlProps } from '../types';

import type { InputBaseComponentProps } from '@mui/material';
const TextMaskCustom = React.forwardRef(function TextMaskCustom(
  props: InputBaseComponentProps,
  ref: React.LegacyRef<InputMask>
) {
  return <InputMask {...props} ref={ref} mask="+1 (999) 999-9999" />;
});

export default React.forwardRef(function InputPhoneControl(
  { error, helperText, ...props }: InputPhoneControlProps,
  ref: any
) {
  return (
    <NoSsr>
      <FormControl fullWidth>
        {props.label && (
          <InputLabel error={error} htmlFor={props.name}>
            {props.label}
          </InputLabel>
        )}

        <OutlinedInput
          ref={ref}
          {...props}
          error={error}
          inputComponent={TextMaskCustom}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    </NoSsr>
  );
});

import React from 'react';

import { ProfileTile } from '@goodfynd/react-web.lib.dashboard';
import { Autocomplete } from '@goodfynd/react-web.ui.auto-complete';

import { useVendorApi } from '../../../hooks/api';
import type { OrganizationFilterOption } from '../AutocompleteVendorOrganizations/types';
import { getVendorSuggestionStatus } from './helpers';

import type { AutocompleteVendorsProps } from './types';
export default function AutocompleteVendors({
  idPropertyName = 'nameId',
  ...props
}: AutocompleteVendorsProps) {
  const api = useVendorApi();
  return (
    <Autocomplete<OrganizationFilterOption>
      label="Vendor"
      getOptions={async (request) => {
        try {
          const { results } = await api.autocomplete(request);
          return results.map((result) => ({
            description: result.nameId,
            image: result.image,
            label: result.uniqueName || result.name,
            value: result[idPropertyName] || result.id,
            status: getVendorSuggestionStatus(result),
          }));
        } catch (error) {
          console.error(error);
          return [];
        }
      }}
      placeholder="Find vendor..."
      renderOption={(props, option) => (
        <ProfileTile
          {...props}
          label={option.label}
          type="vendor"
          status={option.status}
        />
      )}
      {...props}
    />
  );
}

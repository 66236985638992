import CmsPageContent from '@components/cms/CmsPageContent';
import React from 'react';
import { LayoutWithHeader } from '..';

const AuthFallback = () => {
  return (
    <LayoutWithHeader>
      <div style={{ margin: 40, display: 'grid', justifyItems: 'center' }}>
        <CmsPageContent slug="dashboard-login" />
      </div>
    </LayoutWithHeader>
  );
};

export default AuthFallback;

import React, { useCallback, useState } from 'react';
import type { FileRejection} from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import type {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps} from 'react-hook-form';
import {
  Controller
} from 'react-hook-form';
import { toBase64 } from 'utils/file-util';

import { useAlertDialog } from '@goodfynd/react-web.ui.dialog';
import { Icon } from '@goodfynd/react-web.ui.icon';
import { FormHelperText, InputLabel } from '@mui/material';

import ImageCropper from '../ImageCropper';
import { StyledContainer } from '../styles';
import { StyledFileContainer, StyledHelperText, StyledImage } from './styles';

import type { InputFileUploadProps } from './types';
export default function InputFileUpload<T extends FieldValues = FieldValues>({
  css,
  control,
  helperText,
  label,
  name,
  accept,
  crop,
  renderPreview,
  ...props
}: Omit<InputFileUploadProps, 'setValue'>) {
  const [image, setImage] = useState<string | undefined>();

  const openAlert = useAlertDialog();

  return (
    <StyledContainer css={css} {...props}>
      <Controller<T>
        name={name as UseControllerProps<T>['name']}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const onDrop = useCallback(
            async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
              if (rejectedFiles?.length) {
                openAlert({
                  title: 'Invalid File',
                  message: `File "${rejectedFiles[0].file.name}" does not match the accepted types: ${accept}`,
                });
              } else if (acceptedFiles?.length) {
                crop
                  ? setImage(URL.createObjectURL(acceptedFiles[0]))
                  : onChange(
                      (await toBase64(acceptedFiles[0])) as PathValue<
                        T,
                        Path<T>
                      >
                    );
              }
            },
            [onChange]
          );

          const { getRootProps, getInputProps, open } = useDropzone({
            accept,
            onDrop,
          });

          return (
            <>
              <InputLabel>{label}</InputLabel>
              <FormHelperText>{helperText}</FormHelperText>
              {!value ? (
                <StyledFileContainer {...getRootProps()}>
                  <Icon name="image_upload" />
                </StyledFileContainer>
              ) : renderPreview ? (
                renderPreview({
                  file: value as string,
                  openFileDialog: open,
                })
              ) : (
                <StyledFileContainer {...getRootProps()}>
                  <StyledImage alt="file upload" src={value as string} />
                </StyledFileContainer>
              )}
              <input {...getInputProps()} />
              <StyledHelperText error>{error?.message}</StyledHelperText>

              {crop && (
                <ImageCropper
                  open={Boolean(image)}
                  close={() => {
                    setImage(undefined);
                  }}
                  onSave={(src) => {
                    onChange(src as PathValue<T, Path<T>>);
                    setImage(undefined);
                  }}
                  image={image}
                />
              )}
            </>
          );
        }}
      />
    </StyledContainer>
  );
}

import React from 'react';
import type { FieldValues } from 'react-hook-form';

import Text from '@goodfynd/react-web.typography.text';
import { FormHelperText } from '@mui/material';

import InputComboBox from '../InputComboBox';
import { StyledFormControl } from '../styles';
import { PICKUP_CAPACITY_OPTIONS } from './helpers';

import type { InputPickupCapacityProps } from './types';
export const DEFAULT_PICKUP_CAPACITY = 3;

export default function InputPickupCapacity<
  T extends FieldValues = { pickupCapacity: number }
>({
  css,
  defaultValue = DEFAULT_PICKUP_CAPACITY,
  options = PICKUP_CAPACITY_OPTIONS,
  ...props
}: InputPickupCapacityProps<T>) {
  return (
    <StyledFormControl css={css}>
      <InputComboBox<T>
        css={{ paddingBottom: 0 }}
        defaultValue={defaultValue}
        freeSolo
        inputMode="numeric"
        inputRightNode={
          <Text css={{ marginBottom: 12 }} type="body1">
            orders per 15 minute period
          </Text>
        }
        label="Pickup capacity"
        mask="999"
        options={options}
        otherProps={{
          style: {
            minWidth: 116,
          },
        }}
        {...props}
      />
      <FormHelperText style={{ marginLeft: 0 }}>
        How many orders can you prepare and serve in a 15 minute period?
      </FormHelperText>
    </StyledFormControl>
  );
}

import React from 'react';

import { InputNumber } from '@goodfynd/react-web.forms.input-number';

import type { FieldValues } from 'react-hook-form';

import type { InputCurrencyProps } from '@goodfynd/react-web.forms.input-currency';
export default function InputPercent<T extends FieldValues = FieldValues>(
  props: InputCurrencyProps<T>
) {
  return (
    <InputNumber<T>
      {...props}
      // endAdornment={<InputAdornment position="end">%</InputAdornment>}
    />
  );
}

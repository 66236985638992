import React, { useEffect } from 'react';
import { Theme } from 'theme';

import { gtmUtil } from '@goodfynd/react-web.utils.gtm-util';
import Fade from '@mui/material/Fade';
import MuiModal from '@mui/material/Modal';

import {
  StyledBody,
  StyledContainer,
  StyledContent,
  StyledFooter,
  StyledHeader,
  StyledIconButton,
} from './styles';

import type { GtmActionEvent } from '../../types/gtm';
import type { ModalProps } from './types';
export default function Modal({
  children,
  close,
  closeButtonCss,
  containerCss,
  contentCss,
  footer,
  gtmType,
  header,
  headerCss,
  showClose = true,
  ...props
}: ModalProps) {
  useEffect(() => {
    if (gtmType && props.open) {
      const gtmData: GtmActionEvent = {
        event: 'fynd_modal',
        type: gtmType,
        action: 'open',
      };
      gtmUtil.pushEvent(gtmData);
    }
  }, [gtmType, props.open]);

  const handleClose = () => {
    if (gtmType) {
      const gtmData: GtmActionEvent = {
        event: 'fynd_modal',
        type: gtmType,
        action: 'close',
      };
      gtmUtil.pushEvent(gtmData);
    }

    close();
  };

  return (
    <MuiModal closeAfterTransition onClose={handleClose} {...props}>
      <section>
        <Theme>
          <Fade in={props.open}>
            <StyledContainer
              css={{
                maxWidth: 500,
                ...containerCss,
              }}
            >
              <StyledBody>
                {header && (
                  <StyledHeader css={headerCss}>{header}</StyledHeader>
                )}

                {header && showClose && (
                  <StyledIconButton
                    aria-hidden={!props.open}
                    aria-label="Close Modal"
                    css={{
                      '&.MuiButton-root': closeButtonCss,
                    }}
                    icon="close"
                    onClick={handleClose}
                    round
                    display="tertiary"
                  />
                )}

                <StyledContent css={contentCss}>
                  {!header && showClose && (
                    <StyledIconButton
                      css={{
                        '&.MuiButton-root': closeButtonCss,
                      }}
                      icon="close"
                      onClick={handleClose}
                      round
                      display="tertiary"
                    />
                  )}

                  {children}
                </StyledContent>

                {footer && <StyledFooter>{footer}</StyledFooter>}
              </StyledBody>
            </StyledContainer>
          </Fade>
        </Theme>
      </section>
    </MuiModal>
  );
}

import { eachDayOfInterval, Interval } from 'date-fns';

import config from '../config';
import strings from '../config/strings';
import dateVendor from './date-util-vendor';
import * as stringUtil from './string-util';

export const addBusinessDays = (
  date: Date | string,
  days: number,
  useStartOfDay = false
) =>
  useStartOfDay
    ? dateVendor.addBusinessDays(startOfDay(date), days)
    : dateVendor.addBusinessDays(new Date(date), days);

export const addDays = (
  date: Date | string,
  days: number,
  useStartOfDay = false
) =>
  useStartOfDay
    ? dateVendor.addDays(startOfDay(date), days)
    : dateVendor.addDays(new Date(date), days);

export const addHours = (
  date: Date | string,
  hours: number,
  useStartOfDay = false
) =>
  useStartOfDay
    ? dateVendor.addHours(startOfDay(date), hours)
    : dateVendor.addHours(new Date(date), hours);

export const addMinutes = (
  date: Date | string,
  minutes: number,
  useStartOfDay = false
) =>
  useStartOfDay
    ? dateVendor.addMinutes(startOfDay(date), minutes)
    : dateVendor.addMinutes(new Date(date), minutes);

export const addMonths = (
  date: Date | string,
  days: number,
  useStartOfMonth = false
) =>
  useStartOfMonth
    ? dateVendor.addMonths(startOfMonth(date), days)
    : dateVendor.addMonths(new Date(date), days);

export const createTime = (
  hours: number,
  minutes = 0,
  seconds = 0,
  date?: Date
): Date => {
  date = date || new Date();
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    minutes,
    seconds
  );
};

export const differenceInDays = (
  dateLeft: Date | string,
  dateRight: Date | string
) => dateVendor.differenceInDays(new Date(dateLeft), new Date(dateRight));

export const differenceInMinutes = (
  dateLeft: Date | string,
  dateRight: Date | string
) => dateVendor.differenceInMinutes(new Date(dateLeft), new Date(dateRight));

export const displayDateRange = (
  start: Date,
  end: Date,
  formatString = config.dateFormats.monthDayYearLong
) => {
  const startDisplay = format(start, formatString);
  return isSameDay(end, start)
    ? startDisplay
    : `${startDisplay}${config.unicode.dashEnPadded}${format(
        end,
        formatString
      )}`;
};

export const durationDisplay = (
  dates: Interval,
  prefix = 'Event duration:'
) => {
  try {
    let display = prefix;
    const { hours = 0, minutes = 0 } = intervalToDuration(dates);
    if (hours <= 0 && minutes <= 0) {
      return null;
    }

    if (hours > 0) {
      display += ` ${hours} ${stringUtil.pluralize('hour', hours)}`;
    }

    if (minutes > 0) {
      display += ` ${minutes} ${stringUtil.pluralize('minute', minutes)}`;
    }

    return display;
  } catch (error) {
    console.debug('Error updating duration: ', error);
    return null;
  }
};

export const endOfDay = (date: Date | string) =>
  dateVendor.endOfDay(new Date(date));

export const endOfMonth = (date: Date | string) =>
  dateVendor.endOfMonth(new Date(date));

export const endOfWeek = (date: Date | string) =>
  dateVendor.endOfWeek(new Date(date));

export const endOfYear = (date: Date | string) =>
  dateVendor.endOfYear(new Date(date));

export const format = (date: Date | string, format: string) => {
  return dateVendor.format(new Date(date), format);
};

export const getDate = (date: Date | string) =>
  dateVendor.getDate(new Date(date));

export type DateRangesResult = {
  invalid: IntervalDatesPartial[];
  ranges: IntervalDatesPartial[];
};
export const getDateRanges = (
  dates: Date[],
  validate: (date: Date) => boolean = () => true
): DateRangesResult => {
  const invalid: Date[] = [];
  const ranges: IntervalDatesPartial[] = [];

  if (!dates.length) {
    return { invalid: ranges, ranges };
  }

  let lastDate;
  let interval: IntervalDatesPartial = {};
  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    if (!validate(date)) {
      invalid.push(date);
      continue;
    }

    if (!lastDate) {
      lastDate = date;
      interval = { start: date };
      continue;
    }

    if (lastDate.getDate() + 1 !== date.getDate()) {
      ranges.push({
        ...interval,
        end: interval.start !== lastDate ? lastDate : undefined,
      });
      interval = { start: date, end: date };
    }

    lastDate = date;
  }

  lastDate &&
    ranges.push({
      ...interval,
      end: lastDate,
    });

  return { invalid: getDateRanges(invalid).ranges, ranges };
};

export const getDayDisplay = (
  date: Date | string,
  formatString = 'MM/dd/yyyy'
) => {
  if (isToday(date)) {
    return strings.labels.today;
  }

  if (isTomorrow(date)) {
    return strings.labels.tomorrow;
  }

  if (isYesterday(date)) {
    return strings.labels.yesterday;
  }

  return format(date, formatString);
};

export const getDateRangeDisplay = (
  start: Date | string,
  end?: Date | string,
  endDisplay = ''
) => {
  const startDate = new Date(start);
  const endDate = end && new Date(end);
  let display = stringUtil.displayDay(
    startDate,
    config.dateFormats.monthDayYearShort
  );
  if (endDate && !isSameDay(startDate, endDate)) {
    display = format(startDate, config.dateFormats.monthDayYearShort);
    display += config.unicode.dashEnPadded;
    display += format(endDate, config.dateFormats.monthDayYearShort);
  } else if (endDisplay) {
    display = format(startDate, config.dateFormats.monthDayYearShort);
    display += config.unicode.dashEnPadded + endDisplay;
  }

  return display;
};
export const getTimeRangeDisplay = (
  start: Date | string,
  end: Date | string
) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  return `${getTimeDisplay(startDate)}-${getTimeDisplay(endDate)}`;
};

export const getLastPeriodDisplay = (
  start: Date | string,
  end: Date | string,
  text = 'last period'
) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  let display = stringUtil.displayDay(startDate, `'${text}'`);

  if (endDate && !isSameDay(startDate, endDate)) {
    display = text;
  }

  return display;
};

export const getHours = (date: Date | string) =>
  dateVendor.getHours(new Date(date));

export const getMinutes = (date: Date | string) =>
  dateVendor.getMinutes(new Date(date));

export const getTimeDisplay = (
  date: Date | string,
  addSpace = true,
  showMinutes = false
) => {
  date = new Date(date);
  return format(
    date,
    showMinutes || date.getMinutes() > 0
      ? addSpace
        ? config.dateFormats.timeSpace
        : config.dateFormats.time
      : config.dateFormats.hourM
  );
};

export const intervalToDuration = (dates: Interval) =>
  dateVendor.intervalToDuration(dates);

export const isFuture = (date: Date | string) =>
  dateVendor.isFuture(new Date(date));

export const isPast = (date: Date | string) =>
  dateVendor.isPast(new Date(date));

export const isSameDay = (dateLeft: Date | string, dateRight: Date | string) =>
  dateVendor.isSameDay(new Date(dateLeft), new Date(dateRight));

export const isToday = (date: Date | string) =>
  dateVendor.isToday(new Date(date));

export const isTomorrow = (date: Date | string) =>
  dateVendor.isTomorrow(new Date(date));

export const isValid = (date: Date) =>
  date instanceof Date && !isNaN(date.getTime());

export const isYesterday = (date: Date | string) =>
  dateVendor.isYesterday(new Date(date));

export const overlaps = (left: Interval, right: Interval) =>
  dateVendor.overlaps(left, right);

export const printDateRanges = (
  ranges: IntervalDatesPartial[],
  separator = '\n'
) => {
  return ranges
    .map(({ start, end }) =>
      end && start !== end
        ? `${format(start as Date, config.dateFormats.weekDayMonthDay)}${
            config.unicode.dashEn
          }${format(end as Date, 'do, yyyy')}`
        : format(start as Date, config.dateFormats.weekDayMonthDayYear)
    )
    .join(separator);
};

export const removeTime = (date: Date | string): Date => {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const setDate = (date: Date | string, dayOfMonth: number) =>
  dateVendor.setDate(new Date(date), dayOfMonth);

export const setTime = (
  date: Date | string,
  time: Date | string,
  useSeconds = false
): Date => {
  time = new Date(time);
  const update = new Date(date);
  update.setHours(time.getHours());
  update.setMinutes(time.getMinutes());
  update.setSeconds(useSeconds ? time.getSeconds() : 0);
  update.setMilliseconds(0); // always strip milliseconds
  return update;
};

export const startOfDay = (date: Date | string) =>
  dateVendor.startOfDay(new Date(date));

export const startOfHour = (date: Date | string) =>
  dateVendor.startOfHour(new Date(date));

export const startOfMonth = (date: Date | string) =>
  dateVendor.startOfMonth(new Date(date));

export const startOfWeek = (date: Date | string) =>
  dateVendor.startOfWeek(new Date(date));

export const startOfYear = (date: Date | string) =>
  dateVendor.startOfYear(new Date(date));

export const tryFormat = (date: Date | string, format: string) => {
  try {
    return dateVendor.format(new Date(date), format);
  } catch (error) {
    console.warn('date try format error: ', error);
    return '';
  }
};

export const daysOfWeek = Array.from(Array(7)).map((e, i) =>
  format(addDays(startOfWeek(new Date()), i), config.dateFormats.dayOfWeek)
);

export const parseDateRange = (interval: Interval) =>
  eachDayOfInterval(interval);

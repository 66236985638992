import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { createContext, useCallback, useMemo } from 'react';
import { QueryKey, useQuery, useQueryClient } from 'react-query';
import { vendorUtil } from 'utils/index';
import { getOrganizationRoutePath } from 'utils/routes-util';

import config from '@config/index';
import { organizationKeys, vendorKeys } from '@config/queryKeys';
import routes from '@config/routes';
import { useApp } from '@context/AppContext';
import { useContextVendorId } from '@context/VendorContext/useContextVendorId';
import { useSnackbar } from '@goodfynd/react-web.context.snackbar-context';
import { stringUtil } from '@goodfynd/react-web.lib.utils';

import { useProfileApi } from '../../hooks/api';
import { useOrganizationApi } from '../../hooks/api/useOrganizationApi';
import { OrganizationProfile } from '../../types/organizationProfile';
import { useContextOrganizationId } from './useContextOrganizationId';

import type { OrganizationMemberType } from '../../types/shared';
import type {
  OrganizationContextValue,
  OrganizationDispatchContextValue,
  OrganizationProviderProps,
} from './types';
import type { VendorProfile } from '../../types/vendorProfile';
export const OrganizationContext = createContext<
  OrganizationContextValue | undefined
>(undefined);
export const OrganizationDispatchContext = createContext<
  OrganizationDispatchContextValue | undefined
>(undefined);

export default observer(function OrganizationProvider({
  children,
}: OrganizationProviderProps) {
  const { isLoggedIn } = useApp();
  const queryClient = useQueryClient();
  const router = useRouter();
  const snackBar = useSnackbar();

  // TODO: REMOVE after org redesign
  const vendorId = useContextVendorId();
  const profileApi = useProfileApi();

  const { data: orgVendor, isLoading: orgVendorIsLoading } =
    useQuery<VendorProfile>(
      vendorKeys.profile(vendorId),
      () => {
        return profileApi.vendorProfile(vendorId as string);
      },
      {
        enabled: isLoggedIn == true && !!vendorId,
      }
    );
  // TODO END

  // Fetch organization info
  const api = useOrganizationApi();

  const organizationId = useContextOrganizationId(orgVendor?.organizationId);
  const queryKey: QueryKey = useMemo(
    () => organizationKeys.profile(organizationId),
    [organizationId]
  );
  const {
    data: organization = {} as OrganizationProfile,
    isLoading,
    isFetched,
    isFetching,
    refetch: refetchOrganization,
  } = useQuery<OrganizationProfile, unknown>(
    queryKey,
    () => {
      return profileApi.organizationProfile(organizationId as string);
    },
    {
      enabled: isLoggedIn == true && !!organizationId,
    }
  );

  const invalidateOrganization = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey });
  }, [queryClient, queryKey]);

  const setParentOrganization = useCallback(
    async (id: string, type: OrganizationMemberType) => {
      let success = false;

      try {
        await api.setParent(id, organization.nameId, type);
        queryClient.refetchQueries([
          config.queryKeys.organizationStructure,
          organization.id,
        ]);
        router.push(
          getOrganizationRoutePath(
            routes.organization.info.structure,
            organization.nameId
          )
        );
        success = true;
      } catch (error: any) {
        console.error(error);
        snackBar.openSnackbar({
          message: error?.message || 'Unknown error occurred.',
          type: 'error',
        });
      }

      return success;
    },
    [api, organization.id, organization.nameId, queryClient, router, snackBar]
  );

  const value = useMemo(
    () => ({
      isLoading: isLoading || orgVendorIsLoading,
      isFetched,
      isFetching,
      isOrganization: !!router?.query?.organizationId,
      organization,
      organizationId: organization.id,
      organizationNameId: organization.nameId,
    }),
    [
      isFetched,
      isFetching,
      isLoading,
      orgVendorIsLoading,
      organization,
      router?.query?.organizationId,
    ]
  );

  return (
    <OrganizationContext.Provider value={value}>
      <OrganizationDispatchContext.Provider
        value={{
          changeOrganization: useCallback(
            (orgId: string) => {
              router.push(
                stringUtil.replace(routes.organization.overview, {
                  ':organizationId': orgId,
                })
              );
            },
            [router]
          ),
          invalidateOrganization,
          isCurrentOrganization: (entity) =>
            vendorUtil.idEquals(organizationId, entity),
          refetchOrganization,
          setParentOrganization,
        }}
      >
        {children}
      </OrganizationDispatchContext.Provider>
    </OrganizationContext.Provider>
  );
});

import { createFilterOptions } from '@mui/material';

export const isInputOption = (object?: any): object is InputOption => {
  return (
    object &&
    typeof object === 'object' &&
    'label' in object &&
    'value' in object
  );
};
export const filter = createFilterOptions<InputOption>();

export const defaultCreateOption = (label: string): InputOption => ({
  label,
  value: label,
});
